import Vue from 'vue'
import Vuex from 'vuex'

// Global Kompozition Modules
import auth from '@/auth/store'
import workerTasks from '@/store/modules/workerTasks'
import behaviours from '@/store/modules/behaviours'
import behaviourTrees from '@/store/modules/behaviourTrees'
import canvas from '@/store/modules/canvas'
import comments from '@/store/modules/comments'
import components from '@/store/modules/components'
import configurations from '@/store/modules/configurations'
import constants from '@/store/modules/constants'
import domainModel from '@/store/modules/domainModel'
import nodeHistory from '@/store/modules/nodeHistory'
import ideas from '@/store/modules/ideas'
import interfaces from '@/store/modules/interfaces'
import issues from '@/store/modules/issues'
import jira from '@/store/modules/jira'
import model from '@/store/modules/model'
import notes from '@/store/modules/notes'
import notifications from '@/store/modules/notifications'
import releases from '@/store/modules/releases'
import requirements from '@/store/modules/requirements'
import requirementsTableLegacy from '@/store/modules/requirementsTableLegacy'
import reviews from '@/store/modules/reviews'
import specifications from '@/store/modules/specifications'
import tests from '@/store/modules/tests'
import tradestudies from '@/store/modules/tradestudies'
import preferences from '@/store/modules/preferences'

// Next-gen Modules
import specification from '@/store/stores/specification'
import qualificationEvidence from '@/store/stores/qualificationEvidence'
import qualificationRecords from '@/store/stores/qualificationRecords'
import requirement from '@/store/stores/requirement'
import requirementsTable from '@/store/stores/requirementsTable'
import collections from '@/views/Collections/collectionsStore'
import user from './modules/user'

// Theme Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    auth,
    behaviours,
    behaviourTrees,
    canvas,
    comments,
    components,
    configurations,
    constants,
    domainModel,
    nodeHistory,
    ideas,
    interfaces,
    issues,
    jira,
    model,
    notes,
    notifications,
    releases,
    requirements,
    requirementsTableLegacy,
    reviews,
    specifications,
    tests,
    verticalMenu,
    tradestudies,
    workerTasks,

    // Next-gen modules
    specification,
    qualificationEvidence,
    qualificationRecords,
    requirement,
    requirementsTable,
    collections,
    user,
    preferences,
  },
  plugins: [],
  strict: process.env.DEV,
})
