const getDefaultState = () => ({
  taskList: [],
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    ADD_TASK_TO_LIST: (state, taskId) => {
      state.taskList.push(taskId)
    },
    REMOVE_TASK_FROM_LIST: (state, taskId) => {
      state.taskList = state.taskList.filter(id => id !== taskId)
    },
    CLEAR_ALL_TASKS: state => {
      state.taskList = []
    },
  },
  actions: {},
}
