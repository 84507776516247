import { AxiosHttpRequest } from './core/AxiosHttpRequest';
import { AdminCliApi } from './services/AdminCliApi';
import { AuthenticationFlowApi } from './services/AuthenticationFlowApi';
import { BehavioursApi } from './services/BehavioursApi';
import { ClassDiagramsApi } from './services/ClassDiagramsApi';
import { CollectionsApi } from './services/CollectionsApi';
import { ComponentApi } from './services/ComponentApi';
import { ContentApi } from './services/ContentApi';
import { DefaultApi } from './services/DefaultApi';
import { GroupManagementApi } from './services/GroupManagementApi';
import { HistoryApi } from './services/HistoryApi';
import { IssuesApi } from './services/IssuesApi';
import { ModelApi } from './services/ModelApi';
import { NoteApi } from './services/NoteApi';
import { QualificationEvidenceApi } from './services/QualificationEvidenceApi';
import { QualificationRecordsApi } from './services/QualificationRecordsApi';
import { RequirementsApi } from './services/RequirementsApi';
import { RoleManagementApi } from './services/RoleManagementApi';
import { SessionManagementApi } from './services/SessionManagementApi';
import { SnapshotsApi } from './services/SnapshotsApi';
import { SpecificationsApi } from './services/SpecificationsApi';
import { TasksApi } from './services/TasksApi';
import { TradeStudiesApi } from './services/TradeStudiesApi';
import { UserManagementApi } from './services/UserManagementApi';
import { UsersManagementApi } from './services/UsersManagementApi';
import { UtilsApi } from './services/UtilsApi';
export class KompCoreApiClient {
    adminCli;
    authenticationFlow;
    behaviours;
    classDiagrams;
    collections;
    component;
    content;
    default;
    groupManagement;
    history;
    issues;
    model;
    note;
    qualificationEvidence;
    qualificationRecords;
    requirements;
    roleManagement;
    sessionManagement;
    snapshots;
    specifications;
    tasks;
    tradeStudies;
    userManagement;
    usersManagement;
    utils;
    request;
    constructor(config, HttpRequest = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '0.1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.adminCli = new AdminCliApi(this.request);
        this.authenticationFlow = new AuthenticationFlowApi(this.request);
        this.behaviours = new BehavioursApi(this.request);
        this.classDiagrams = new ClassDiagramsApi(this.request);
        this.collections = new CollectionsApi(this.request);
        this.component = new ComponentApi(this.request);
        this.content = new ContentApi(this.request);
        this.default = new DefaultApi(this.request);
        this.groupManagement = new GroupManagementApi(this.request);
        this.history = new HistoryApi(this.request);
        this.issues = new IssuesApi(this.request);
        this.model = new ModelApi(this.request);
        this.note = new NoteApi(this.request);
        this.qualificationEvidence = new QualificationEvidenceApi(this.request);
        this.qualificationRecords = new QualificationRecordsApi(this.request);
        this.requirements = new RequirementsApi(this.request);
        this.roleManagement = new RoleManagementApi(this.request);
        this.sessionManagement = new SessionManagementApi(this.request);
        this.snapshots = new SnapshotsApi(this.request);
        this.specifications = new SpecificationsApi(this.request);
        this.tasks = new TasksApi(this.request);
        this.tradeStudies = new TradeStudiesApi(this.request);
        this.userManagement = new UserManagementApi(this.request);
        this.usersManagement = new UsersManagementApi(this.request);
        this.utils = new UtilsApi(this.request);
    }
}
